import "./style/visual.less";
import "./style/ui.less";
import "core-js/stable";

import "./assets/icon-32.png";
import "./assets/icon-64.png";
import { analyticsHandler, ignoredEditEvents } from "@zebrabi/analytics-tracking/AnaliticsHandler";
import { ActionType } from "@zebrabi/licensing/ActionType";
import { OwnerUserInfo, CurrentUserInfo } from "@zebrabi/licensing/components/UserInfo";
import { licensing } from "@zebrabi/licensing/Licensing";
import ActionObserver from "@zebrabi/licensing/observers/ActionObserver";
import { DataHelper } from "./dataHelper";

import * as d3 from "d3";
import * as drawing from "./library/drawing";
import * as uiPopups from "./ui/popups";
import * as debug from "./library/debug";
import { fixLabelHeights } from "./charting/plotters/analyticsPlotter";
import { drawTopNForm } from "./ui/contextMenu";

import { ViewModel } from "./settings/viewModel";
import { TABLE_SETTINGS_NAME, VarianceSettings } from "./settings/varianceSettings";
import { ABSOLUTE, RELATIVE, FormulaEditMode } from "./definitions";
import { Plotter } from "./charting/plotters/plotter";
import { SliderHandler } from "./ui/sliderHandler";
import { viewModelFactory } from "./settings/viewModelFactory";
import {
	DISPLAY, BLOCK, NONE, DIV, PX, CLICK, COLLAPSE_RECTANGLE, FILL_OPACITY, STROKE_OPACITY, COLLAPSE_ARROW_ICON, OPACITY,
	AUTO, HIDDEN, TITLE, WIDTH, HEADER_ROW, EMPTY, SCROLL, OVERFLOW, REPORT_AREA, GRAND_TOTAL, DEFS, CONTEXT_MENU_DIV, COLUMN_SETTINGS_DIV, COLUMN_ADDER_LIST,
	COLUMN_REMOVE_LIST, MOUSELEAVE, LogType, BOTTOM_SHADOW, OVERFLOW_X, OVERFLOW_Y, LEFT, POINTER_EVENTS, HEIGHT, MARGIN_TOP, POSITION,
	WHITE_SPACE, Z_INDEX, TOP_N_RECTANGLE, CUSTOMER_STYLE_VISIBLE, RIGHT_SHADOW, BOTH_SHADOW, FROZEN_CATEGORIES, INFO_BOX_CONTAINER,
	COMMENT_BOX_RESIZE_LINE, ZEBRABI_TABLE_CONTAINER, FLEX, CENTER, MOUSEENTER
} from "./library/constants";
import { plotterFactory } from "./charting/plotters/plotterFactory";
import { VerticalPlotter } from "./charting/plotters/verticalPlotter";
import { HorizontalPlotter } from "./charting/plotters/horizontalPlotter";

import { DataPoint } from "./charting/dataPoint";
import { FormulaEditor } from "./ui/formulaEditor";
import { valueFormatter } from "powerbi-visuals-utils-formattingutils";
import { Viewport } from "./interfaces";
import InfoBoxHandler from "./ui/InfoBoxHandler";
import { DataView, DataViewMetadataColumn } from "@zebrabi/matrix-data";

import {
	subscribeToXSpreadSheetData,
	subscribeToTableDataEditorData,
	initTableDataEditorStore,
	VisualType,
} from '@zebrabi/table-data-editor';
import type { TableDataEditorData } from "@zebrabi/table-data-editor";
import { initTablesChooserStore } from "@zebrabi/tables-chooser/mount";
import { mountReactTablesChooser } from "@zebrabi/tables-chooser/react";
import { getOfficeStorageMatrixData, setOfficeStorageMatrixData } from '@zebrabi/table-data';
import GlobalToolbar from "@zebrabi/global-toolbar/dist/lib/components/GlobalToolbar";
import AboutSwitcher from "./toolbar/components/AboutSwitcher";
import TablesDataEditorObserver from "./toolbar/observers/TablesDataEditorObserver";
import DataTableEditorSwitcher from "./toolbar/components/DataTableEditorSwitcher";
import { getOfficeSettings } from "@zebrabi/office-settings";
import AccountSwitcher from "./toolbar/components/AccountSwitcher";
import { UNLOCK_ALL_FEATURES_URL, START_TRIAL_URL } from "../../licensing/constants";
import { ProductType } from "../../licensing/licensing.types";
import { openURL } from "@zebrabi/licensing/helpers";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import { OrganizationStyleData, getAvailableOrganizationStyles, getOrganizationStyleById } from "@zebrabi/data-helpers/organizationStyles";
import { extractExistingAnalyticsId, initializeAnalyticsOptOut, initializeAnalyticsReporting } from "@zebrabi/analytics-tracking/helpers";
import { EventHandlerProxy, flagHandler, Platform } from "@zebrabi/zebrabi-core";
import fsm from "../../../flagsmith.json";
import GlobalToolbarFeature from "../../global-toolbar-feature/GlobalToolbarFeature";
import GlobalToolbarOld from "@zebrabi/global-toolbar-old/components/GlobalToolbar";
import FieldsTablesSwitcher from "./toolbar/components/FieldsTablesSwitcher";
import FieldsTablesSwitcherObserver from "./toolbar/observers/FieldsTablesSwitcherObserver";
import SettingsTablesSwitcher from "./toolbar/components/SettingsTablesSwitcher";
import SettingsTablesSwitcherObserver from "./toolbar/observers/SettingsTablesSwitcherObserver";
import { delay } from "./helpers";

import { initExcelDataLinkingStore } from "@zebrabi/excel-data-linking/mount";
import { ExcelDataLinkingStore } from "@zebrabi/excel-data-linking";
import { transformSharePointResponse } from "@zebrabi/excel-data-linking/helpers";
import { dataLinkingEventBus } from "@zebrabi/excel-data-linking/DataLinkingEventBus";
import SharepointBrowserAPI from "@zebrabi/sharepoint-api-browser/SharepointBrowserAPI";
import { IconButton, IconName } from "@zebrabi/design-library";
import { SHOW_CHOOSER_LINK_ID } from "@zebrabi/data-helpers/editData";

/* global console, document, Excel, Office */

Office.onReady(async (info) => {
	if (process.env.DEBUG_LOG === "true") {
		console.debug("Office ready info: ", info);
	}

	const isEdgeOrIE = window.navigator.userAgent.indexOf("Trident") !== -1 || window.navigator.userAgent.indexOf("Edge") !== -1;
	if (isEdgeOrIE) {
		const loadingElementMsg = document.getElementById("main-message");
		if (loadingElementMsg) {
			loadingElementMsg.textContent = "This Office environment is not supported. Please upgrade your Microsoft Office to newer version.";
		}
		return;
	}

	if (info.host === Office.HostType.Excel || info.host === Office.HostType.PowerPoint) {
		let settings = getOfficeSettings(TABLE_SETTINGS_NAME);
		let visual = Visual.getInstance(settings);

		/** Settings are null when we are on TableChooserScreen
		 * Only after choosing the table should we show the data editor **/
		if (settings === null) {
			Visual.isFirstLoad = true;
		}

		await visual.update(settings);
	}
});


export class Visual {
	public welcomeHeader: HTMLElement;

	private static instance: Visual;
	private dataHelper: DataHelper;
	private hostWrapperElement: HTMLElement;
	public hostElement: HTMLElement;
	public infoBoxElement: HTMLElement;
	static infoBox: HTMLElement;
	public commentBoxResizeLineElement: HTMLElement;
	static commentBoxResizeLine: HTMLElement;
	static visualDiv: HTMLElement;
	static title: HTMLElement;
	private titleHeight: number;
	static headersDiv: HTMLElement;
	static headers: d3.Selection<SVGElement, any, any, any>;
	private grandTotalDiv: HTMLElement;
	private grandTotalTable: HTMLElement;
	static grandTotalRow: d3.Selection<SVGElement, any, any, any>;
	static categoriesHeaderDiv: HTMLElement;
	static categoriesHeaders: d3.Selection<SVGElement, any, any, any>;
	private tablesContainer: HTMLElement;
	private headersContainer: HTMLElement;
	private categoriesDiv: HTMLElement;
	static categoriesSelection: d3.Selection<SVGElement, any, any, any>;
	private grandTotalCategoryDiv: HTMLElement;
	static grandTotalCategoriesSelection: d3.Selection<SVGElement, any, any, any>;
	static headersOuterDiv: HTMLElement;
	static headersDoubleOuterDiv: HTMLElement;
	public svg: d3.Selection<SVGElement, any, any, any>;
	private defs: d3.Selection<SVGElement, any, any, any>;
	private reportArea: d3.Selection<SVGElement, any, any, any>;
	public isChooserCrossTables: boolean = false;
	public resetPLFCReorder: boolean = false;

	static selectedCategories: string[] = [];
	static isFilterApplied(): boolean {
		return Visual.selectedCategories?.length > 0;
	};

	private _viewModel: ViewModel;
	set viewModel(viewModel: ViewModel) {
		this._viewModel = viewModel;
		this.globalToolbar?.notify(new Map([["viewModel", viewModel]]));
		//this.globalToolbar?.render();
	}
	get viewModel() { return this._viewModel; }


	public _settings: VarianceSettings;
	set settings(settings: VarianceSettings) {
		this._settings = settings;
		this.globalToolbar?.notify(new Map([["settings", settings]]));
		//this.globalToolbar?.render();
	}
	get settings(): VarianceSettings {
		return this._settings;
	}

	public _dataView: DataView;
	set dataView(dataView: DataView) {
		this._dataView = dataView;
		this.globalToolbar?.notify(new Map([["dataView", dataView]]));
		//this.globalToolbar?.render();
	}
	get dataView(): DataView {
		return this._dataView;
	}

	public static visualSettings: VarianceSettings;

	private locale: string;
	private plotter: Plotter;
	static width: number;
	static height: number;
	private sliderHandler: SliderHandler;
	static topNForm: d3.Selection<HTMLElement, any, any, any>;
	static titleMenuSettings: d3.Selection<HTMLElement, any, any, any>;
	static columnSettings: d3.Selection<HTMLElement, any, any, any>;
	static columnAdderList: d3.Selection<HTMLElement, any, any, any>;
	static columnRemoveList: d3.Selection<HTMLElement, any, any, any>;
	static performanceLogs: string[];
	private addInBodyEl: HTMLElement;
	static element: HTMLElement;
	private globalNonce: Object;
	private showCompanyStyle = CUSTOMER_STYLE_VISIBLE.toString() === "true";
	static formatterMap: Map<string, valueFormatter.IValueFormatter>;
	static groupsAndCategoryFields: string;
	private fe: FormulaEditor;
	static formulaEditPosition: DataPoint;
	static formulaEditMode: FormulaEditMode;
	static commentAutoCurrentNumber: number;
	static viewport: Viewport;
	private availableWidth: number;

	static formulaManagerOpen: boolean;
	// Setting this to true disables all hover events across reportArea svg elements. Currently only used in comment markers dragging.
	static disableHoverEvents: boolean;

	static isInFocus: boolean;
	static tempWidth: number;
	static tempHeight: number;
	static shouldChangeSize: boolean;
	static fieldsForm: d3.Selection<HTMLElement, any, any, any>;
	static showFieldsTab: boolean;

	private isLicenseFetchingDone = false;

	private isTableDataEditorVisible: boolean;
	private widthModifier: number;
	private heightModifier: number;
	private globalToolbar: GlobalToolbar | GlobalToolbarOld;

	static tableDataEditorSwitcher: DataTableEditorSwitcher;
	static isFirstLoad: boolean = false;

	private currentOrganizationStyle: OrganizationStyleData;
	public availableOrganizationStyles: OrganizationStyleData[];

	static isFirstTimeInsert: boolean;

	public getOrganizationStyleSettings(): OrganizationStyleData {
		return this.currentOrganizationStyle;
	}
	public removeOrganizationStyle() {
		this.currentOrganizationStyle = null;
	}

	public static getInstance(settings?: VarianceSettings) {
		if (!Visual.instance) {
			Visual.instance = new Visual(settings);
		}
		return Visual.instance;
	}

	public updateAboutText() {
		if (this.globalToolbar) {
			this.globalToolbar.notify(new Map([["aboutinfo", "true"]]));
		}
	}

	public updateUserInfoText() {
		if (this.globalToolbar) {
			this.globalToolbar.notify(new Map([["userinfo", "true"]]));
		}
	}

	public applyDefaultOrganizationStyle(styleId: number) {
		if (Visual.isFirstLoad) {
			this.applyOrganizationStyleById(styleId);
		}
	}

	public applyOrganizationStyleById(styleId: number) {
		getOrganizationStyleById(styleId).then(orgSettings => {
			if (!orgSettings) {
				return;
			}
			this.currentOrganizationStyle = orgSettings;
			if (this.settings) {
				this.settings.setOrganizationStyleSettings(this.currentOrganizationStyle);
				this.update(this.settings);
			}
		});
	}

	public loadOrganizationStyles(organizationId: string, userId: string) {
		if (!organizationId) {
			return;
		}
		getAvailableOrganizationStyles(organizationId, userId).then((availableStyles) => {
			this.availableOrganizationStyles = availableStyles;
			availableStyles?.forEach(styleData => {
				if (styleData.isDefault) {
					this.applyDefaultOrganizationStyle(styleData.id);
				}
			});
		});
	}

	constructor(settings?: VarianceSettings) {
		Visual.isFirstTimeInsert = !Office.context.document.settings.get(TABLE_SETTINGS_NAME);
		this.dataHelper = new DataHelper(this, settings);

		this.handleSignIn();

		//if (Office.context.host === Office.HostType.PowerPoint) {
		// setOfficeStorageMatrixData(simpleMatrixData)
		initTablesChooserStore();
		if (Office.context.host === Office.HostType.PowerPoint) {
			mountReactTablesChooser();
		}
		initTableDataEditorStore({ visualType: VisualType.TABLES });
		initExcelDataLinkingStore();

		// mountTableDataEditor({ visualType: VisualType.TABLES }, this.mainElement)
		// Read initial table data from Office storage
		const tableData = getOfficeStorageMatrixData();
		this.dataView = tableData;

		// Local storage event listener
		subscribeToXSpreadSheetData(async (tableData: DataView) => {
			if (Visual.isFirstLoad) {
				if (Office.context.host === Office.HostType.PowerPoint && (Office.context.document.mode !== Office.DocumentMode.ReadOnly)) {
					setTimeout(() => Visual.tableDataEditorSwitcher.open(), 100);
				}
				let viewModel = viewModelFactory([tableData], this.locale, true, settings);
				this.update(viewModel.settings);
			}

			setOfficeStorageMatrixData(tableData);
			this.dataView = tableData;
			if (Office.context.host === Office.HostType.Excel) {
				await this.dataHelper.writeDataViewToWorksheet();
			}

			let viewModel = viewModelFactory([tableData], this.locale, true, this.settings);
			this.settings = viewModel.settings;
			this.update(viewModel.settings);
		});

		subscribeToTableDataEditorData((data: TableDataEditorData) => {
			this.update(this.settings);
		});

		//this.locale = "en-US";
		this.locale = Office.context.contentLanguage || "en-US";
		this.addInBodyEl = document.getElementsByTagName("body")[0];
		Visual.element = this.addInBodyEl;
		this.welcomeHeader = document.getElementById("welcome-header");
		this.welcomeHeader.focus(); // focusing this element enables inset from selected range in Excel
		this.sliderHandler = new SliderHandler(this, this.addInBodyEl);
		this.hostWrapperElement = document.createElement('main');
		this.hostWrapperElement.classList.add('zebrabi-tables-wrapper');

		this.hostElement = document.createElement('div');
		this.hostElement.classList.add('zebrabi-table-container');

		this.hostWrapperElement.appendChild(this.hostElement);
		this.sliderHandler.slider.appendChild(this.hostWrapperElement);

		this.infoBoxElement = document.createElement("aside");
		this.infoBoxElement.classList.add(INFO_BOX_CONTAINER);
		Visual.infoBox = this.infoBoxElement;
		this.hostWrapperElement.appendChild(this.infoBoxElement);

		this.commentBoxResizeLineElement = document.createElement(DIV);
		this.commentBoxResizeLineElement.classList.add(COMMENT_BOX_RESIZE_LINE);
		Visual.infoBox = this.infoBoxElement;
		Visual.commentBoxResizeLine = this.commentBoxResizeLineElement;
		Visual.titleMenuSettings = null;

		this.hostWrapperElement.appendChild(this.hostElement);
		this.hostWrapperElement.appendChild(this.commentBoxResizeLineElement);
		this.hostWrapperElement.appendChild(this.infoBoxElement);

		this.sliderHandler.addSliderButtons();
		Visual.shouldChangeSize = false;
		EventHandlerProxy.createInstance(Platform[process.env.PLATFORM]);

		flagHandler.init({
			api: process.env.FLAGSMITH_HOST,
			defaultFlags: fsm.flags,
			environment: process.env.MODE === "development" ? process.env.FLAGSMITH_DEV : process.env.FLAGSMITH_PROD,
			preventFetch: process.env.FLAGSMITH_LOCAL_ONLY === "true",
			identity: process.env.FLAGSMITH_USER ?? null,
		}).then(() => {
			new GlobalToolbarFeature(this).init(settings);
			this.update(settings);
		}, () => {
			new GlobalToolbarFeature(this).init(settings);
		});

		(<any>window).animateLabels = false;
		(<any>window).visualHtmlElement = this.addInBodyEl;
		(<any>window).currentScrollTop = 0;
		(<any>window).currentScrollLeft = 0;
		(<any>window).lastScrollTop = 0;
		(<any>window).lastScrollLeft = 0;
		Visual.performanceLogs = [];
		Visual.formatterMap = new Map<string, valueFormatter.IValueFormatter>();
		this.fe = null;
		Visual.formulaEditMode = FormulaEditMode.None;
		Visual.formulaManagerOpen = false;
		Visual.disableHoverEvents = false;

		Visual.isInFocus = false;
		Visual.showFieldsTab = true;


		Visual.viewport = this.getViewport(this.addInBodyEl);

		window.onresize = () => {
			Visual.viewport = this.getViewport(this.addInBodyEl);
			this.constructViewModelAndUpdate(this.settings);
		};

		if (Office.context.host === Office.HostType.Excel && !settings) {
			this.setFocus();
		}
	}

	private getViewport(el: HTMLElement): Viewport {
		return {
			width: el.parentElement?.clientWidth || el.clientWidth,
			height: el.parentElement?.clientHeight || el.clientHeight
		};
	}

	private setFocus() {
		// her we set focus to some element to deselect the add-in and enable "insert from range" functionality in Excel
		let el = document.createElement("input");
		this.hostElement.appendChild(el);
		this.hostElement.blur();
		el.focus();
		el.hidden = true;
	}

	public async update(updateSettings: VarianceSettings) {
		if (process.env.DEBUG_LOG === "true") {
			console.debug("updating... settings: ", updateSettings);
		}

		this.dataView = await this.dataHelper.getDataView();
		if (process.env.DEBUG_LOG === "true") {
			console.debug("getDataView():", this.dataView);
		}

		this.welcomeHeader.style.display = "none";
		await this.constructViewModelAndUpdate(updateSettings);
	}

	public async constructViewModelAndUpdate(updateSettings?: VarianceSettings) {
		if (!this.dataView) {
			return;
		}

		// If we should resize the visual due to "focus mode"
		if (Visual.shouldChangeSize) {
			if (Office.context.host === Office.HostType.Excel) {
				await Excel.run(async (context) => {
					let shapes = context.workbook.worksheets.getActiveWorksheet().shapes;
					shapes.load("items");
					await context.sync();
					//todo: this way could select any add-in shape on worksheet not only current one, should be improved/removed
					let shape = shapes.items.find(val => val.name.includes("App") || val.name.includes("Add-in"));
					Visual.viewport.width = Visual.isInFocus ? Math.max(1200, Visual.tempWidth) : Visual.tempWidth;
					Visual.viewport.height = Visual.isInFocus ? Math.max(450, Visual.tempHeight) : Visual.tempHeight;
					shape.width = Visual.viewport.width * 3 / 4;
					shape.height = Visual.viewport.height * 3 / 4;
				});
			}
			Visual.shouldChangeSize = false;
			this.constructViewModelAndUpdate(updateSettings);
			return;
		}

		Visual.viewport = this.getViewport(this.addInBodyEl);

		Visual.commentAutoCurrentNumber = 0;
		let tempScrollTop = (<any>window).currentScrollTop;
		if (this.groupsOrCategoriesFieldsChanged([this.dataView])) {
			(<any>window).currentScrollTop = 0;
		}

		//Get viewmodel
		if (!this.dataView.errorMessage) {
			this.sliderHandler.slider.style.display = null;

			try {
				this.viewModel = viewModelFactory([this.dataView], this.locale, true, updateSettings);
				this.viewModel.settings = new Proxy(this.viewModel.settings, {
					set: (target: VarianceSettings, p: string, value: any): boolean => {
						let oldValue = target[p];
						if (oldValue !== value) {
							target[p] = value;

							// Send edit event to analytics, but do not send ignored events
							if (!ignoredEditEvents.includes(p)) {
								analyticsHandler.report(`edit/${p}`);
							}
						}
						return true;
					}
				});
			}
			catch (err) {
				this.showErrorMessage("error parsing data: If you are using pivot table please try to switch to 'Tabular' layout");
				console.log(err);
				this.globalToolbar?.render();
				return;
			}
			this.settings = this.viewModel.settings;
			Visual.visualSettings = this.settings;
			if (process.env.DEBUG_LOG === "true") {
				console.debug("viewModel: ", this.viewModel);
			}

			// TODO: formula edit...
			if (Visual.formulaEditMode !== FormulaEditMode.None && !Visual.isInFocus) {
				Visual.formulaEditMode = FormulaEditMode.None;
				Visual.formulaEditPosition = null;
			}
			// licensing... skip
			if (this.settings.showAdSlide) {
				this.plotAdvertSlide();
				this.sliderHandler.refreshUIelements(this.availableWidth, Visual.viewport, this.settings);
				return;
			}

			// landing page? skip

			this.setVisualElementsAndPlot();
			d3.selectAll(".icon-div").remove();
			if (!(<any>window).shouldKeepPickers) {
				d3.selectAll(".pcr-app").remove();
				(<any>window).shouldKeepPickers = false;
			}

			if (this.groupsOrCategoriesFieldsChanged([this.dataView]) && tempScrollTop + parseInt(Visual.visualDiv.style.height) <= parseInt(this.svg.attr(HEIGHT))
				+ (this.settings.freezeHeaders ? 0 : this.plotter.getHeadersHeight()) + (this.settings.showFrozenTitle() ? 0 : this.plotter.titleHeight)) {
				Visual.visualDiv.scrollTop = tempScrollTop;
				(<any>window).lastScrollTop = 0;
				this.onScroll(this);
			}
			Visual.groupsAndCategoryFields = this.getGroupsAndCategoriesFieldsString([this.dataView]);

			if (this.settings.showTopNForm) {
				drawTopNForm(this.settings, null, this.viewModel, this.viewModel.getChartHierarchy(0, 0));
			}

			if (Visual.formulaEditMode !== FormulaEditMode.None && this.fe) {
				this.fe.setFormulaElements();
			}

			if (Visual.formulaEditMode === FormulaEditMode.None) {
				Visual.tempHeight = Visual.viewport.height;
				Visual.tempWidth = Visual.viewport.width;
			}
			Visual.visualSettings?.persist();
		}
		else {
			this.showErrorMessage(this.dataView.errorMessage);
		}

		this.globalToolbar?.render();
		this.checkWatermark();
	}

	private showErrorMessage(errorMsg: string) {
		this.sliderHandler.slider.style.display = "none";
		this.sliderHandler.slider.style.width = "100%";
		this.sliderHandler.slider.style.height = "100%";
		this.welcomeHeader.style.display = null;
		d3.select(this.welcomeHeader).classed("error-message", true);
		d3.select(this.welcomeHeader).html(errorMsg);

		const chooserLink = this.welcomeHeader.querySelector<HTMLAnchorElement>(`#${SHOW_CHOOSER_LINK_ID}`);
		if (chooserLink) {
			chooserLink.addEventListener(CLICK, () => mountReactTablesChooser(true));
		}

		const kbLink = <HTMLAnchorElement>this.welcomeHeader.querySelector("#error-msg-kb-url");
		if (kbLink) {
			kbLink.addEventListener(CLICK, (e) => {
				e.preventDefault();
				e.stopPropagation();
				openURL(kbLink.href);
			});
		}
		const templateLink = <HTMLAnchorElement>this.welcomeHeader.querySelector("#error-msg-template-url");
		if (templateLink) {
			templateLink.addEventListener(CLICK, (e) => {
				e.preventDefault();
				e.stopPropagation();
				openURL(templateLink.href);
			});
		}
	}

	private async plotAdvertSlide() {
		const currentUserInfo: CurrentUserInfo = licensing.getCurrentUser();
		const isTrialUser = currentUserInfo?.isTrial();
		let content = await fetch("brandslides_tables.html");
		let text = await content.text();
		let el = document.createElement(DIV);
		el.style.display = FLEX;
		el.style.justifyContent = CENTER;
		el.style.alignItems = CENTER;
		el.style.width = "100%";
		el.style.height = "100%";
		el.innerHTML = text;

		let buyUrl = (isTrialUser ? UNLOCK_ALL_FEATURES_URL : START_TRIAL_URL)
			.replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint)
			.replace("$visual", process.env.ZBI_VISUAL);
		this.hostElement.innerHTML = "";
		this.hostElement.appendChild(el);

		const upgradeLink = <HTMLAnchorElement>el.querySelector("#upgrade-link");
		upgradeLink.text = isTrialUser ? "Upgrade" : "Start Free Trial";
		upgradeLink.addEventListener(CLICK, () => openURL(buyUrl));
	}

	public clearPlotArea() {
		while (this.hostElement.firstChild) {
			this.hostElement.removeChild(this.hostElement.firstChild);
		}
	}

	private setVisualElementsAndPlot() {
		this.clearPlotArea();
		this.setVisualElements(this.settings);
		if (this.settings.shouldShowSlider()) {
			this.sliderHandler.showSliderButtons();
			this.sliderHandler.addSliderEventHandlers(this.settings);
		}
		else {
			this.sliderHandler.hideSliderButtons();
		}

		this.availableWidth = Visual.viewport.width;

		if (this.settings.showCommentBox) {
			if (this.settings.isCommentBoxVertical()) {
				this.availableWidth = this.availableWidth * Number.parseFloat(this.settings.commentBoxSize);
			}
		}

		// TODO: FIX WIDTH&HEIGHT
		Visual.height = Visual.viewport.height;
		Visual.width = this.availableWidth;
		this.plotter = plotterFactory(this.viewModel.settings.chartType, this.viewModel, this.availableWidth, Visual.viewport.height, this.reportArea, this.svg, this.locale);
		this.plot(this, false);
		this.titleHeight = this.plotter.titleHeight;
		// INFOBOX
		//debugger
		//const infoBox = new InfoBoxHandler(this.settings, "." + INFO_BOX_CONTAINER);
		const infoBox = new InfoBoxHandler(this.settings, `.${INFO_BOX_CONTAINER}`, `.${COMMENT_BOX_RESIZE_LINE}`, `.${ZEBRABI_TABLE_CONTAINER}`);

		Visual.infoBox = this.infoBoxElement = infoBox.clearCommentBox();
		Visual.commentBoxResizeLine = this.commentBoxResizeLineElement = infoBox.clearCommentsBoxDragLine();

		if (this.settings.showCommentBox) {
			infoBox.drawCommentBox(this.viewModel, 0, this.plotter.titleHeight);
			infoBox.setCommentBoxPlacement(Visual.commentBoxResizeLine.parentElement);
			infoBox.drawDragLine(Visual.viewport.width, Visual.viewport.height);
		}
		else {
			infoBox.removeCommentBoxSettings(true);
		}


		if (this.settings.averageLabelShow || this.settings.constantLabelShow || this.settings.medianLabelShow || this.settings.percentileLabelShow) {
			fixLabelHeights(Visual.visualDiv, this.grandTotalDiv, this.settings);
		}
		(<any>window).animateLabels = false;
		(<any>window).expandCategory = null;
		let mouseWheelFunction = this.onScroll.bind(this);
		let visual = this;
		Visual.visualDiv.onscroll = () => {
			mouseWheelFunction(visual);
		};
		Visual.visualDiv.scrollTop = (<any>window).currentScrollTop;
		Visual.visualDiv.scrollLeft = (<any>window).currentScrollLeft;
		if (this.settings.freezeHeaders) {
			Visual.headersDiv.scrollLeft = (<any>window).currentScrollLeft;
		}
		if (this.settings.showFrozenRowGrandTotal()) {
			this.grandTotalTable.scrollLeft = (<any>window).currentScrollLeft;
		}
		if (this.settings.showFrozenCategories()) {
			this.categoriesDiv.scrollTop = (<any>window).currentScrollTop;
			if (!this.settings.freezeTitle) {
				const titleScrollHeight = this.titleHeight - Visual.visualDiv.scrollTop;
				Visual.title.style.height = (titleScrollHeight > 0 ? titleScrollHeight : 0) + "px";
			}
		}
		this.sliderHandler.refreshUIelements(this.availableWidth, Visual.viewport, this.settings);
		d3.select(document.body).on(MOUSELEAVE, () => {
			this.hideUIElements();
		});
		d3.select(document.body).on(MOUSEENTER, () => {
			this.showUIElements();
		});

		Visual.visualDiv.addEventListener(CLICK, () => {
			Visual.topNForm.style(DISPLAY, NONE);
			Visual.topNForm.selectAll("*").remove();
			drawing.removeBlur([Visual.headers, Visual.grandTotalRow, Visual.categoriesSelection, Visual.grandTotalCategoriesSelection], [Visual.visualDiv, Visual.title, Visual.headersDiv, this.infoBoxElement]);
			Visual.columnSettings.style(DISPLAY, NONE);
			Visual.columnAdderList.style(DISPLAY, NONE);
			if (Visual.titleMenuSettings !== null) {
				Visual.titleMenuSettings.remove();
				Visual.titleMenuSettings = null;
			}
			(<any>window).switchingDecimals = false;
			if (this.settings.showTopNForm) {
				this.settings.persistTopNForm(false);
			}
		});
		if (this.grandTotalDiv) {
			this.grandTotalDiv.addEventListener(CLICK, () => {
				Visual.topNForm.style(DISPLAY, NONE);
				Visual.topNForm.selectAll("*").remove();
				Visual.columnSettings.style(DISPLAY, NONE);
				Visual.columnAdderList.style(DISPLAY, NONE);
				(<any>window).switchingDecimals = false;
			});
		}

		if (Visual.visualDiv.scrollLeft === 0 && Visual.visualDiv.scrollTop === 0) {
			(<any>window).switchingDecimals = false;
		}

		const isVericalScrollbar = (Visual.visualDiv.offsetWidth - Visual.visualDiv.clientWidth);
		if (visual.settings.freezeHeaders && isVericalScrollbar > 0) {
			Visual.headersDiv.querySelector('svg').style.paddingRight = isVericalScrollbar + "px";
		}

		if (Visual.isFirstLoad) {	// handles showing add-in UI when the add-in is inserted from tables chooser
			this.showUIElements();
		}
	}

	private hideUIElements() {
		if (this.settings?.shouldShowSlider?.()) {
			this.sliderHandler.sliderButtonRight.style.opacity = "0";
		}
		d3.selectAll("." + COLLAPSE_RECTANGLE).attr(FILL_OPACITY, 0).attr(STROKE_OPACITY, 0);
		d3.selectAll("." + TOP_N_RECTANGLE).attr(STROKE_OPACITY, 0);
		d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 0);

		// check if ppt spreadsheed is open, should not be hidden on mouse leave
		const shouldKeepOpenToolbar = Office.context.host === Office.HostType.PowerPoint && Visual.tableDataEditorSwitcher &&
			document.querySelector("." + Visual.tableDataEditorSwitcher.getStyleClassName()).classList.contains("active");
		if (!shouldKeepOpenToolbar) {
			this.globalToolbar?.hide();
		}
	}

	private showUIElements() {
		if (this.settings?.shouldShowSlider?.()) {
			this.sliderHandler.sliderButtonRight.style.opacity = "0.2";
		}
		d3.selectAll("." + TOP_N_RECTANGLE).attr(FILL_OPACITY, 0.1).attr(STROKE_OPACITY, 1);
		d3.selectAll("." + COLLAPSE_RECTANGLE).attr(FILL_OPACITY, 0.8);
		d3.selectAll("." + COLLAPSE_ARROW_ICON).attr(OPACITY, 1);

		this.globalToolbar?.show();
	}

	public setVisualElements(settings: VarianceSettings) {
		// "is in focus"
		if (Visual.formulaEditMode !== FormulaEditMode.None && Visual.isInFocus) {
			// crate formula editor
			this.fe = new FormulaEditor(settings, this.hostElement);
		}

		// Main vertically scrollable div
		Visual.visualDiv = document.createElement(DIV);
		Visual.visualDiv.style.overflowY = AUTO;
		// If we are not showing the scrollbar on the frozen grand total div we have to show it
		// on the main visual div.
		if (!settings.showFrozenRowGrandTotal()) {
			Visual.visualDiv.style.overflowX = AUTO;
		}
		else {
			Visual.visualDiv.style.overflowX = HIDDEN;
		}
		if (settings.showFrozenCategories()) {
			// Visual.visualDiv.style.display = "inline-block";
			Visual.visualDiv.style.height = "100%";
		} else {
			Visual.visualDiv.style.width = "100%";
		}

		//Top N form
		Visual.topNForm = d3.select(this.hostElement).append(DIV).classed("top-n-form", true);

		// Title
		this.tablesContainer = <HTMLElement>d3.select(this.hostElement)
			.append("section")
			.classed("table-container", true)
			.style(OVERFLOW, HIDDEN)
			.style(DISPLAY, "flex")
			//.style(HEIGHT, "100%")
			.style("flex-shrink", 1)
			.node();

		let titleParent = this.hostElement;
		Visual.title = <HTMLElement>d3.select(titleParent)
			.append(DIV)
			.classed(TITLE, true)
			.style(WHITE_SPACE, "nowrap")
			.style(OVERFLOW, "hidden")
			.style("flex-shrink", 0)
			.style("flex-grow", 0)
			//.style(WIDTH, this.awailableWidth + PX)
			.node();
		if (settings.freezeTitle) {
			Visual.title.style.position = "static";
		} else {
			Visual.title.style.position = RELATIVE;
		}
		Visual.titleMenuSettings?.remove();
		Visual.titleMenuSettings = null;

		// Headers
		let headerParent = settings.freezeHeaders ? this.hostElement : Visual.visualDiv;
		if (settings.freezeHeaders && settings.showFrozenCategories()) {
			this.headersContainer = <HTMLElement>d3.select(this.hostElement).append(DIV)
				.style(DISPLAY, "flex")
				.node();

			Visual.categoriesHeaderDiv = <HTMLElement>d3.select(this.headersContainer).append(DIV)
				.style(POSITION, RELATIVE)
				.append(DIV)
				.classed(HEADER_ROW, true)
				.node();
			Visual.categoriesHeaders = drawing.createSvgElement(Visual.categoriesHeaderDiv, EMPTY);
			Visual.headersDoubleOuterDiv = <HTMLElement>d3.select(this.headersContainer).append(DIV)
				.style(POSITION, RELATIVE)
				.node();
			Visual.headersOuterDiv = <HTMLElement>d3.select(Visual.headersDoubleOuterDiv).append(DIV)
				.style(POSITION, RELATIVE)
				.style(OVERFLOW_X, HIDDEN)
				.style(OVERFLOW_Y, AUTO)
				// .style(Z_INDEX, 5)
				.node();
			headerParent = Visual.headersOuterDiv;
		}
		Visual.headersDiv = <HTMLElement>d3.select(headerParent).append(DIV).classed(HEADER_ROW, true)
			.style(POSITION, "relative")
			.node();
		if (settings.freezeHeaders) {
			Visual.headersDiv.style.overflow = HIDDEN;
		}

		Visual.headers = drawing.createSvgElement(Visual.headersDiv, EMPTY);
		Visual.headers
			.style(DISPLAY, BLOCK)
			.style(OVERFLOW, SCROLL)
			.style(POSITION, ABSOLUTE)
			.style(Z_INDEX, 2)
			.style(POINTER_EVENTS, NONE);

		// Categories
		if (settings.showFrozenCategories()) {
			this.categoriesDiv = <HTMLElement>d3.select(this.tablesContainer)
				.append(DIV)
				.style(HEIGHT, "100%")
				.style(OVERFLOW, HIDDEN)
				.classed(FROZEN_CATEGORIES, true)
				.node();
			if (!settings.freezeHeaders) {
				Visual.categoriesHeaderDiv = <HTMLElement>d3.select(this.categoriesDiv).append(DIV)
					.style(POSITION, RELATIVE)
					.append(DIV)
					.classed(HEADER_ROW, true)
					.node();
				Visual.categoriesHeaders = drawing.createSvgElement(Visual.categoriesHeaderDiv, EMPTY);
			}
			Visual.categoriesSelection = drawing.createSvgElement(this.categoriesDiv, EMPTY);
		}

		// Visual
		this.svg = drawing.createSvgElement(Visual.visualDiv, "visual");
		this.svg.style(OVERFLOW, HIDDEN);
		this.svg.style(DISPLAY, BLOCK);
		this.reportArea = drawing.createGroupElement(this.svg, REPORT_AREA);
		this.tablesContainer.appendChild(Visual.visualDiv);
		this.hostElement.appendChild(this.tablesContainer);

		// Grand total
		if (settings.showFrozenRowGrandTotal()) {

			this.grandTotalDiv = <HTMLElement>d3.select(this.hostElement)
				.append(DIV)
				.style(OVERFLOW, HIDDEN)
				.style(DISPLAY, "flex")
				.style("flex-shrink", "0")
				.classed(GRAND_TOTAL, true)
				.node();
			if (settings.showFrozenCategories()) {
				this.grandTotalCategoryDiv = <HTMLElement>d3.select(this.grandTotalDiv)
					.append(DIV)
					.style(OVERFLOW_Y, HIDDEN)
					.style(OVERFLOW_X, HIDDEN)
					.classed(FROZEN_CATEGORIES, true)
					.node();
				Visual.grandTotalCategoriesSelection = drawing.createSvgElement(this.grandTotalCategoryDiv, EMPTY);
			}

			this.grandTotalTable = <HTMLElement>d3.select(this.grandTotalDiv)
				.append(DIV)
				.style(OVERFLOW_X, AUTO)
				.style(OVERFLOW_Y, HIDDEN)
				.node();

			let headersDiv = Visual.headersDiv;
			this.grandTotalTable.onscroll = (event) => {
				Visual.visualDiv.scrollLeft = this.grandTotalTable.scrollLeft;
				if (settings.freezeHeaders) {
					headersDiv.scrollLeft = this.grandTotalTable.scrollLeft;
				}
			};
			Visual.grandTotalRow = drawing.createSvgElement(this.grandTotalTable, EMPTY);
		}

		// Patterns
		this.defs = this.svg.append(DEFS);
		drawing.addPatternDefinitions(this.defs, this.settings.colorScheme, this.settings.categoryFormatSettings);
		drawing.addElasticDefinitions(this.defs);
		drawing.addBlurDefinitions(this.svg);

		// Context menu
		let context = document.createElement(DIV);
		context.className = `${CONTEXT_MENU_DIV} ${CONTEXT_MENU_DIV}-theme`;
		this.hostElement.appendChild(context);
		context.onmouseleave = () => {
			let contextMenuDisplay = d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY);
			if (contextMenuDisplay === BLOCK) {
				d3.select("." + CONTEXT_MENU_DIV).style(DISPLAY, NONE);
			}
		};

		Visual.columnSettings = d3.select(this.hostElement).append(DIV).classed(COLUMN_SETTINGS_DIV, true);
		Visual.columnAdderList = d3.select(this.hostElement).append(DIV).classed(COLUMN_ADDER_LIST, true);
		Visual.columnRemoveList = d3.select(this.hostElement).append(DIV).classed(COLUMN_REMOVE_LIST, true);
		Visual.columnAdderList.on(MOUSELEAVE, () => {
			Visual.columnAdderList.style(DISPLAY, NONE);
		});
		Visual.columnRemoveList.on(MOUSELEAVE, () => {
			Visual.columnAdderList.style(DISPLAY, NONE);
		});

		// is in focus
		if (Visual.isInFocus && Visual.formulaEditMode === FormulaEditMode.None) {
			uiPopups.showZoomValuePopup(this.hostElement, Math.round(this.settings.focusModeFontZoomPercentage));
			this.hostElement.onwheel = (e) => {
				if (!e.ctrlKey) {
					return;
				}
				e.preventDefault();
				let delta = e.deltaY > 0 ? -10 : 10;
				let newFocusPercentageValue = Math.max(100, Math.min(200, settings.focusModeFontZoomPercentage + delta));
				if (newFocusPercentageValue === settings.focusModeFontZoomPercentage) {
					return;
				}
				else {
					settings.focusModeFontZoomPercentage = newFocusPercentageValue;
				}
				let minFontSize = 8;
				let fontDelta = delta / 10;
				settings.labelFontSize = Math.max(minFontSize, settings.labelFontSize + fontDelta);
				settings.titleFontSize = Math.max(minFontSize, settings.titleFontSize + fontDelta);
				settings.groupTitleFontSize = Math.max(minFontSize, settings.groupTitleFontSize + fontDelta);

				this.viewModel.chartGroups.markElementsAsNotPlotted();
				this.viewModel.calculateExtremes();
				this.viewModel.calculateCumulativeExtremes();
				this.setVisualElementsAndPlot();
				uiPopups.showZoomValuePopup(this.hostElement, Math.round(this.settings.focusModeFontZoomPercentage));
			};
		}
		else {
			this.hostElement.onwheel = null;
		}
	}

	private onScroll(visual: Visual): void {
		(<any>window).currentScrollTop = Visual.visualDiv.scrollTop;
		(<any>window).currentScrollLeft = Visual.visualDiv.scrollLeft;
		if (visual.settings.showFrozenCategories()) {
			visual.categoriesDiv.scrollTop = Visual.visualDiv.scrollTop;
			if (!this.settings.freezeTitle) {
				const titleScrollHeight = this.titleHeight - Visual.visualDiv.scrollTop;
				Visual.title.style.height = (titleScrollHeight > 0 ? titleScrollHeight : 0) + "px";
			}

		}
		let verticalScrollAmount = Math.abs((<any>window).lastScrollTop - (<any>window).currentScrollTop);
		if (visual.settings.showFrozenRowGrandTotal()) {
			visual.grandTotalTable.scrollLeft = Visual.visualDiv.scrollLeft;
		}

		if (visual.plotter instanceof VerticalPlotter) {
			if (verticalScrollAmount > 200) {
				visual.removeOneTimeElements(visual);
				visual.plot(visual, true);
				// syncState(visual.selectionManager, visual.viewModel);
			}
		}
		if (visual.plotter instanceof HorizontalPlotter) {
			let horizontalScrollAmount = Math.abs((<any>window).lastScrollLeft - (<any>window).currentScrollLeft);
			if (verticalScrollAmount > 200 || horizontalScrollAmount > 200) {
				visual.removeOneTimeElements(visual);
				visual.plot(visual, true);
				// syncState(visual.selectionManager, visual.viewModel);
				(<any>window).lastScrollLeft = (<any>window).currentScrollLeft;
				(<any>window).lastScrollTop = (<any>window).currentScrollTop;
			}
		}

		if (visual.settings.averageLabelShow || visual.settings.constantLabelShow || visual.settings.medianLabelShow || visual.settings.percentileLabelShow) {
			fixLabelHeights(Visual.visualDiv, visual.grandTotalDiv, visual.settings);
		}
		let headersDiv = d3.select(Visual.headersDiv);
		if (Visual.visualDiv.scrollLeft > 0 && visual.settings.showFrozenCategories()) {
			if (visual.settings.showFrozenRowGrandTotal()) {
				d3.select(visual.grandTotalCategoryDiv).classed(RIGHT_SHADOW, true);
			}
			d3.select(visual.categoriesDiv).classed(RIGHT_SHADOW, true);
			if (visual.settings.freezeHeaders) {
				d3.select(Visual.categoriesHeaderDiv).classed(RIGHT_SHADOW, false).classed(BOTH_SHADOW, false);
				d3.select(Visual.categoriesHeaderDiv).classed(Visual.visualDiv.scrollTop > 0 ? BOTH_SHADOW : RIGHT_SHADOW, true);
			}
		} else {
			if (visual.settings.showFrozenRowGrandTotal()) {
				d3.select(visual.grandTotalCategoryDiv).classed(RIGHT_SHADOW, false);
			}
			d3.select(visual.categoriesDiv).classed(RIGHT_SHADOW, false);
			if (visual.settings.freezeHeaders) {
				d3.select(Visual.categoriesHeaderDiv).classed(RIGHT_SHADOW, false).classed(BOTH_SHADOW, false);
			}
		}
		if (Visual.visualDiv.scrollTop > 0 && visual.settings.freezeHeaders) {
			if (visual.settings.showFrozenCategories()) {
				d3.select(Visual.headersDoubleOuterDiv).classed(BOTTOM_SHADOW, true);
				d3.select(Visual.categoriesHeaderDiv).classed(BOTTOM_SHADOW, false).classed(BOTTOM_SHADOW, false);
				d3.select(Visual.categoriesHeaderDiv).classed(Visual.visualDiv.scrollLeft > 0 ? BOTH_SHADOW : BOTTOM_SHADOW, true);
			} else {
				headersDiv.classed(BOTTOM_SHADOW, true);
			}
		}
		else {
			if (visual.settings.showFrozenCategories()) {
				d3.select(Visual.headersDoubleOuterDiv).classed(BOTTOM_SHADOW, false);
				d3.select(Visual.categoriesHeaderDiv).classed(BOTTOM_SHADOW, false).classed(BOTH_SHADOW, false);
			} else {
				headersDiv.classed(BOTTOM_SHADOW, false);
			}
		}
		let titleDiv = d3.select(visual.hostElement).selectAll(`.${TITLE}`);
		if (Visual.visualDiv.scrollTop > 0 && visual.settings.freezeTitle && !visual.settings.freezeHeaders) {
			titleDiv.classed(BOTTOM_SHADOW, true);
		}
		else {
			titleDiv.classed(BOTTOM_SHADOW, false);
		}
		if (visual.settings.freezeHeaders) {
			let headersDivElement = <HTMLElement>headersDiv.node();
			headersDivElement.scrollLeft = (<any>window).currentScrollLeft;
		}
		if (!(<any>window).switchingDecimals) {
			Visual.columnSettings.style(DISPLAY, NONE);
		} else {
			Visual.columnSettings.style(LEFT, (<any>window).columnSettingsLeft + PX);
		}
		Visual.columnRemoveList.style(DISPLAY, NONE);
		(<any>window).switchingDecimals = false;
	}

	private plot(visual: Visual, partialPlot: boolean): any {
		visual.plotter.plotReport(partialPlot);
		//syncState(visual.selectionManager, visual.viewModel);
		if (partialPlot) {
			return;
		}
		let headersHeight = visual.plotter.getHeadersHeight();
		let titleHeight = visual.plotter.titleHeight;
		let grandTotalHeight = visual.plotter.grandTotalHeight;
		let totalHeight = titleHeight + headersHeight + visual.plotter.totalVisualHeight + grandTotalHeight;
		let totalWidth = Math.round(visual.plotter.totalWidth);
		let svgWidth = totalWidth;
		let visualDivHeight = 0;
		if (this.isScrollbarNeeded(totalHeight, Visual.height)) {
			visualDivHeight = Visual.height;
			if (visual.settings.showFrozenRowGrandTotal()) {
				visualDivHeight -= grandTotalHeight;
			}
			if (visual.settings.freezeHeaders) {
				visualDivHeight -= headersHeight;
			}
			if (visual.settings.showFrozenTitle()) {
				visualDivHeight -= titleHeight;
			}
			// Adding space for scrollbar on the grand total div
			if (this.isScrollbarNeeded(totalWidth, Visual.width - (this.settings.showFrozenCategories() ? this.plotter.categoriesWidth : 0)) && visual.settings.showFrozenRowGrandTotal()) {
				visualDivHeight -= 17;
			}
			// If we need to vertically scroll we add some width to the svg since otherwise the grand total and / or header divs are not the same
			// size as the main visual's div. This causes problems when scrolling to the right most part of the visual.
			if (this.isScrollbarNeeded(totalWidth, Visual.width - (this.settings.showFrozenCategories() ? this.plotter.categoriesWidth : 0))) {
				svgWidth += 25;
			}
		}
		else {
			if (visual.settings.showFrozenRowGrandTotal()) {
				visualDivHeight = visual.plotter.totalVisualHeight;
				grandTotalHeight = Math.max(grandTotalHeight, Visual.height - titleHeight - headersHeight - visualDivHeight);
				if (!visual.settings.freezeHeaders) {
					visualDivHeight += headersHeight;
				}
				if (visual.settings.showNonFrozenTitle()) {
					visualDivHeight += titleHeight;
				}
				if (visual.settings.plottingHorizontally() && !visual.settings.showFrozenRowGrandTotal()) {
					visualDivHeight += visual.plotter.bottomMargin;
				}
			}
			else {
				visualDivHeight = Visual.height;
				if (visual.settings.freezeHeaders) {
					visualDivHeight -= headersHeight;
				}
				if (visual.settings.showFrozenTitle()) {
					visualDivHeight -= titleHeight;
				}
			}
		}
		if (this.settings.showFrozenCategories()) {
			Visual.categoriesSelection.attr(HEIGHT, visual.plotter.totalVisualHeight);
			visual.categoriesDiv.style.width = this.plotter.categoriesWidth + PX;
			Visual.categoriesSelection.attr(WIDTH, this.plotter.categoriesWidth + PX);
			Visual.visualDiv.style.width = `calc(100% - ${this.plotter.categoriesWidth}${PX})`;
			let topMargin = 0;
			if (!visual.settings.freezeHeaders) {
				topMargin += headersHeight;
			}

			Visual.categoriesSelection.style(MARGIN_TOP, visual.settings.freezeHeaders ? topMargin + PX : 0 + PX);
			Visual.categoriesHeaderDiv.style.width = this.plotter.categoriesWidth + PX;
			Visual.categoriesHeaderDiv.style.height = headersHeight + PX;
			Visual.categoriesHeaders.style(WIDTH, this.plotter.categoriesWidth + PX);
			Visual.categoriesHeaders.style(HEIGHT, headersHeight + PX);
			if (!this.settings.showFrozenTitle() && !this.settings.freezeHeaders) {
				Visual.categoriesHeaderDiv.style.height = headersHeight + titleHeight + PX;
			}
		}
		Visual.headersDiv.style.height = headersHeight + PX;

		if (visual.settings.showFrozenCategories() && visual.settings.freezeHeaders) {
			Visual.headersDoubleOuterDiv.style.width = "100%";
		}

		let svgHeight = visual.plotter.totalVisualHeight;

		visual.svg
			.attr(WIDTH, svgWidth)
			.attr(HEIGHT, svgHeight);
		Visual.headers
			.attr(WIDTH, svgWidth + (visual.settings.showFrozenCategories() && visual.settings.freezeHeaders ? this.plotter.categoriesWidth : 0))
			.attr(HEIGHT, headersHeight);

		if (this.settings.showFrozenCategories()) {
			(<HTMLElement>document.querySelector("." + FROZEN_CATEGORIES + '> svg')).style.marginBottom = (Visual.visualDiv.offsetHeight - Visual.visualDiv.clientHeight) + PX;
		}
		if (visual.settings.showFrozenRowGrandTotal()) {
			Visual.grandTotalRow
				.attr(WIDTH, svgWidth)
				.attr(HEIGHT, grandTotalHeight);

			// Due to an issue of flex elements sometimes not rendering in older Chromium browsers (~80), an otherwise unneccesary height has been set to the grandTotal elements.
			let isHorizontalScrollbar = svgWidth > Visual.visualDiv.getBoundingClientRect().width;
			let isVerticalScrollbar = svgHeight > Visual.visualDiv.getBoundingClientRect().height;
			if (isHorizontalScrollbar && isVerticalScrollbar) {
				grandTotalHeight += 6;
			}

			if (visual.settings.showFrozenCategories()) {
				visual.grandTotalTable.style.width = `calc(100% - ${this.plotter.categoriesWidth}px)`;
				visual.grandTotalDiv.style.width = `100%`;
				Visual.grandTotalCategoriesSelection.attr(WIDTH, this.plotter.categoriesWidth)
					.attr(HEIGHT, grandTotalHeight);
			}
			visual.grandTotalDiv.style.height = `${grandTotalHeight}px`;
			Visual.grandTotalRow.attr(HEIGHT, grandTotalHeight);

		}
		if (debug.shouldLog(LogType.Dimensions)) {
			console.log("Visual div height:", visualDivHeight);
			console.log("Visual svg width:", svgWidth);
			console.log("Visual svg height:", visual.plotter.totalVisualHeight);
			console.log("Headers svg width:", svgWidth);
			console.log("Headers svg height: ", headersHeight);
			if (visual.settings.showFrozenRowGrandTotal()) {
				console.log("Grand total svg height:", svgWidth);
				console.log("Grand total svg width:", grandTotalHeight);
			}
		}
	}

	public removeOneTimeElements(visual: Visual) {

	}

	public isScrollbarNeeded(visualDimension: number, available: number): boolean {
		return visualDimension > available;
	}

	public groupsOrCategoriesFieldsChanged(dataViews: DataView[]): boolean {
		return (Visual.groupsAndCategoryFields !== this.getGroupsAndCategoriesFieldsString(dataViews));
	}

	public getGroupsAndCategoriesFieldsString(dataViews: DataView[]): string {
		if (dataViews && dataViews[0] && dataViews[0]?.metadata?.columns) {
			let str = "";
			dataViews[0].metadata.columns.forEach(c => {
				if (c.roles && (c.roles["Category"] === true || c.roles["Group"] === true)) {
					str += c.displayName + (c.roles["Category"] === true ? "C" : "G") + ";";
				}
			});
			return str;
		} else {
			return null;
		}
	}

	private initGlobalToolbarOld() {
		const globalToolbar = new GlobalToolbarOld(this.addInBodyEl);

		const fieldsSwitcher = new FieldsTablesSwitcher(this.settings, this.dataView, this.viewModel);
		const fieldsSwitcherObserver = new class extends FieldsTablesSwitcherObserver { };
		fieldsSwitcherObserver.update = (formData: Map<string, any>) => {
			this.updateSettingsFromForm(formData);
			Visual.visualSettings.persist();
			this.constructViewModelAndUpdate(Visual.visualSettings);
		};
		fieldsSwitcher.attach(fieldsSwitcherObserver);

		const settingsSwitcher = new SettingsTablesSwitcher(this.settings, this.dataView, this.viewModel);
		const settingsSwitcherObserver = new class extends SettingsTablesSwitcherObserver { };
		settingsSwitcherObserver.update = (formData: Map<string, any>) => {
			this.updateSettingsFromForm(formData);
			Visual.visualSettings.persist();
			this.constructViewModelAndUpdate(Visual.visualSettings);
		};
		settingsSwitcher.attach(settingsSwitcherObserver);

		if (Office.context.host === Office.HostType.PowerPoint) {
			// Observer type determines what object gets the returned data if you set it in the switcher notify call
			const dataTableEditorObserver = new class extends TablesDataEditorObserver { };
			dataTableEditorObserver.update = (formData: Map<string, any>): void => {
				this.update(Visual.visualSettings);
			};

			const tableDataEditorSwitcher = new DataTableEditorSwitcher();
			tableDataEditorSwitcher.attach(dataTableEditorObserver);
			globalToolbar.add(tableDataEditorSwitcher);
			Visual.tableDataEditorSwitcher = tableDataEditorSwitcher;
		}
		globalToolbar.add(fieldsSwitcher);
		globalToolbar.add(settingsSwitcher);
		globalToolbar.add(new AccountSwitcher());
		globalToolbar.add(new AboutSwitcher(this.settings));

		this.globalToolbar = globalToolbar;
	}

	public initGlobalToolbar(settings?: any) {
		const globalToolbar = new GlobalToolbar(this.addInBodyEl);
		globalToolbar.render();
		this.globalToolbar = globalToolbar;
	}

	private updateSettingsFromForm(formData: Map<string, any>) {
		for (const [key, value] of formData.entries()) {
			const splitKey = key.split(".");
			let setting = Visual.visualSettings;

			if (splitKey.length > 1) {
				while (splitKey.length - 1 && (setting = setting[splitKey.shift()]));
			}

			setting[splitKey.shift()] = value;
		}
	}

	private async handleSignIn() {
		const signInObserver = new ActionObserver();
		signInObserver.update = async (data: string, action: string) => {
			if (action === ActionType.SignInSuccess) {
				return await this.onSignIn();
			}

			if (action === ActionType.SignOut) {
				return await this.onSignOut();
			}

		};
		signInService.attach(signInObserver);

		try {
			await signInService.silentSignIn();
		} catch (error) {
			if (process.env.DEBUG_LOG === "true") {
				console.debug(error.name, error);
			}

			if (!licensing.getOwnerUser()) {
				signInService.renderSignIn();
			}
		}
	}

	private refreshGlobalToolbar() {
		this.constructViewModelAndUpdate(this.settings);
		this.globalToolbar?.render();
	}

	private shouldRefreshPPTLinkedData(): boolean {
		if (Office.context.host !== Office.HostType.PowerPoint) {
			return false;
		}

		const dataLinkingStore = ExcelDataLinkingStore.get();
		if (!dataLinkingStore?.loaded?.autoRefresh) {
			return false;
		}

		const timeSinceLastRefresh = new Date().getTime() - (dataLinkingStore?.loaded?.dataRefreshTimestamp?.getTime() ?? 0);
		if (timeSinceLastRefresh < 5000) {  // arbitrary 5s threshold
			return false;
		}

		return true;
	}

	private async refreshPPTLinkedData() {
		const loadRangeAndSetData = async (accessToken: string) => {
			const loaded = ExcelDataLinkingStore.get().loaded;
			const { driveId, fileId, worksheetId, workbookId, range } = loaded;

			const browser = SharepointBrowserAPI.createInstance(accessToken);
			const response = workbookId
				? await browser.getTableRange(driveId, fileId, workbookId)
				: await browser.getRange(driveId, fileId, worksheetId, range);


			if (response?.message) {
				console.error("Error loading range data. Graph API error: ", response.message);
				// set error message in store
				ExcelDataLinkingStore.set({
					loaded: {
						...loaded,
						errorMessage: response.message,
					},
				});

				// open datagrid on error
				setTimeout(() => Visual.tableDataEditorSwitcher.open(), 100);

				return;
			}

			const rows = transformSharePointResponse(response.values);
			// update refresh data timestamp
			ExcelDataLinkingStore.set({
				loaded: {
					...loaded,
					errorMessage: null,
					dataRefreshTimestamp: new Date(),
				}
			});

			dataLinkingEventBus.dispatch("range-selected", rows);
		};

		this.showDataLoadingBanner();

		await signInService.authenticateWithFilePermissions(licensing.getCurrentUser()?.organizationId);
		await loadRangeAndSetData(signInService.getSharepointToken());

		this.removeDataLoadingBanner();
	}

	private showDataLoadingBanner() {
		const dataLoadingBanner = document.createElement("div");
		dataLoadingBanner.className = "data-refreshing-banner";
		const icon = new IconButton(IconName.ArrowSync, false);
		icon.appendTo(dataLoadingBanner);

		dataLoadingBanner.appendChild(document.createTextNode(" Loading data from Excel..."));
		this.addInBodyEl.appendChild(dataLoadingBanner);
	}

	private removeDataLoadingBanner() {
		const dataLoadingBanner = document.querySelector(".data-refreshing-banner");
		dataLoadingBanner?.remove();
	}

	private async onSignIn() {
		await this.checkLicensing();
		const organizationId = licensing.getCurrentUser()?.organizationId;
		const userId = licensing.getCurrentUser()?.userId;
		this.loadOrganizationStyles(organizationId, userId);
		this.logSignInAnalytics();

		if (this.shouldRefreshPPTLinkedData()) {
			this.refreshPPTLinkedData();
		}

		this.refreshGlobalToolbar();
	}

	private async onSignOut() {
		this.globalToolbar.hide();
		licensing.clearWatermark();
	}

	private checkWatermark() {
		if (!this.isLicenseFetchingDone) {
			return;
		}

		const ownerUser = licensing.getOwnerUser();
		const currentUser = licensing.getCurrentUser();

		if (!currentUser) {
			licensing.renderLicensingUnavailableWatermark();
			return;
		}

		// at the moment when visual is newly added it has no owner
		if (!ownerUser && currentUser && !currentUser.getLicense().hasLicense) {
			licensing.renderFreeLicenseWatermark();
			return;
		}

		if (licensing.freeViewerMode()) {
			licensing.renderFreeLicenseWatermark();
			return;
		}

		if (!signInService.isSignedIn()
			&& ownerUser
			&& !ownerUser.getLicense()?.hasLicense) {
			licensing.renderFreeLicenseWatermark();
			return;
		}

		// no watermark is required, clear any existing watermark
		licensing.clearWatermark();
	}

	private async checkLicensing() {
		try {
			await Promise.race([
				licensing.fetchUsers(),
				delay(10000).then(() => { throw new Error("Fetching licensing data timed out"); })
			]);

			const currentUser = licensing.getCurrentUser();
			const ownerUser = licensing.getOwnerUser();

			if (!ownerUser && !currentUser?.getLicense()?.hasLicense) {
				licensing.renderNoLicenseOverlay();
				return;
			}

			if (!currentUser?.getLicense()?.hasLicense
				&& currentUser?.isSameUserAs(ownerUser)
				&& (!ownerUser?.lastDisplayedOverlay
					|| ownerUser?.lastDisplayedOverlay.getTime() < (new Date()).getTime() - (30 * 24 * 3600 * 1000)
				)) {

				if (ownerUser) {
					ownerUser.lastDisplayedOverlay = new Date();
				}
				await OwnerUserInfo.setOwnerInfo(ownerUser);

				licensing.renderNoLicenseOverlay();
				return;
			}
		} catch (error) {
			if (error instanceof Error && error.message === "Fetching licensing data timed out") {
				console.error('Licensing service not available:', error.message);
			}
		} finally {
			this.isLicenseFetchingDone = true;
		}
	}

	private logSignInAnalytics() {
		const currentUser = licensing.getCurrentUser();
		if (!currentUser) {
			return;
		}

		Visual.isFirstTimeInsert = initializeAnalyticsReporting("tables", analyticsHandler, Visual.isFirstTimeInsert);
		analyticsHandler.identify({ userId: currentUser?.userId, organizationId: currentUser?.organizationId, plan: licensing.getPlanString() });
		initializeAnalyticsOptOut(analyticsHandler, currentUser, this.globalToolbar).finally(() => {
			// We wait for opt out to be correctly set before extracting existing analytics from current file
			extractExistingAnalyticsId(analyticsHandler);
		});
	}
}
